import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import Layout from '../components/Layout'
import SliceZone from '../components/SliceZone'
import { FiMail as MailIcon } from "react-icons/fi"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export const ContactTemplate = ({ data }) => {
  const contact = data.prismicContact || {}
  const sidebar = data.prismicLayout || {}

  const { lang, type, url } = contact || {}
  const alternateLanguages = contact.alternate_languages || []

  // console.log("contact", contact)

  return (
    <Layout
      metadata={{
        title: contact.data.title.text,
        description: contact.data.description.text
      }}
      sidebar={sidebar.data}
      activeDocMeta={{
        lang,
        type,
        url,
        alternateLanguages,
      }}
    >
      <SliceZone slices={data.prismicContact.data.body} />

      <div className="max-w-screen-md flex flex-col mb-10 md:mt-10">       
        <OutboundLink
          href={`mailto:${contact.data.email.text}`}
          rel="noreferrer"
          className="flex items-center mb-8"
        >
          <MailIcon className="text-gray-500 mr-4" size={26} />
          <span className="border-b">{contact.data.email.text}</span>
        </OutboundLink>

        <form
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          className="flex flex-col items-start"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />

          <label htmlFor="name" className="mb-2">
            Name
          </label>
          <input
            className="w-full bg-blue-50 px-4 py-2 border border-blue-100 mb-2 rounded focus:border-2 focus:border-primary"
            id="name"
            name="name"
            required
          />

          <label htmlFor="email" className="mb-2">
            Email
          </label>
          <input
            className="w-full bg-blue-50 px-4 py-2 border border-blue-100  mb-2 rounded focus:border-2 focus:border-primary"
            type="email"
            id="email"
            name="email"
            required
          />

          <label htmlFor="message" className="mb-2">
            Message
          </label>
          <textarea
            className="w-full bg-blue-50 px-4 py-2 border border-blue-100 mb-2 rounded focus:border-2 focus:border-primary"
            id="message"
            name="message"
            required
            rows="6"
          />

          <button
            type="submit"
            className="min-w-165 my-4 rounded-full inline-flex items-center justify-center px-14 py-4 font-semibold border border-transparent leading-6 text-white bg-primary hover:bg-secondary focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            Send
          </button>
        </form>
      </div>
    </Layout>
  )
}

export const query = graphql`
query contactQuery($lang: String) {
  prismicContact(lang: {eq: $lang}) {
    alternate_languages {
      uid
      type
      lang
      url
    }
    lang
    url
    type
    data {
      email {
        text
      }
      title {
        text
      }
      description {
        text
      }
      no_index
      body {
        ... on PrismicContactBodyCta {
          slice_type
          primary {
            container_center
            heading {
              text
            }
            link_button {
              url
            }
            text {
              text
            }
            text_button {
              text
            }
          }
          slice_type
        }
        ... on PrismicContactBodyTextImage {
          slice_type
          primary {
            order
            image {
              url
              alt
            }
            text {
              raw
            }
          }
        }
        ... on PrismicContactBodyFullWidthImage {
          slice_type
          primary {
            image {
              url
              alt
            }
          }
        }
        ... on PrismicContactBodyImageGrid {
          slice_type
          primary {
            type
            desktop
            mobile
            gap
          }
          items {
            image {
              url
              alt
            }
          }
        }
        ... on PrismicContactBodyRichText {  
          slice_type        
          primary {
            container_center
            text_align
            content {
              raw
            }
          }
        }
        ... on PrismicContactBodyEmbedVideo {  
          slice_type        
          primary {
            link {
              url
            }
          }
        }
      }
    }
  }
  prismicLayout(lang: {eq: $lang}) {
    ...LayoutFragment
   }
}
`

export default withPreview(ContactTemplate)
